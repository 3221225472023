@import url('https://fonts.googleapis.com/css2?family=Syne Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers:wght@400&display=swap');

* { font-family: 'Syne Mono', sans-serif; }
h1, h1 span { font-family: 'Bangers', sans-serif; }

body {
  background-image: url("../images/bg.jpg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

main {
  display: flex;
  flex-grow: 1;
  text-align: center;
  flex-direction: column;
}

h1 {
  color: #FAF374;
  font-size: 6rem;
  margin: 8rem 0 0;
}

.specs {
  background-color: rgba(0, 0, 0, .3);
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.specs p {
  margin: 0;
  color: #FAF374;
  font-weight: bold;
  font-size: 2rem;
  text-shadow: 0 3px 0 rgba(0, 0, 0, 0.5);
}

.actions {
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.btn {
  appearance: button;
  background-color: rgba(255, 212, 92, .25);
  cursor: pointer;
  display: block;
  font-size: 2rem;
  padding: 6px 12px;
  text-align: center;
  text-decoration-color: rgb(13, 110, 253);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
  text-indent: 0px;
  text-rendering: auto;
  text-shadow: none;
  text-size-adjust: 100%;
  text-transform: none;
  transition-delay: 0s, 0s, 0s, 0s;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-property: color, background-color, border-color, box-shadow;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  user-select: none;
  vertical-align: middle;
  word-spacing: 0px;
  writing-mode: horizontal-tb;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-border-image: none;
}

.neon-text {
  animation: flicker 1.5s infinite alternate;
  color: #fff;
}

@keyframes flicker {
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
    text-shadow:
    0 0 4px #fff,
    0 0 11px #fff,
    0 0 19px #fff,
    0 0 40px #0fa,
    0 0 80px #0fa,
    0 0 90px #0fa,
    0 0 100px #0fa,
    0 0 150px #0fa;
  }

  20%, 24%, 55% {
    text-shadow: none;
  }
}

.is-desktop {
  display: none;
}

.audit-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .5);
  padding: 6px;
}

.audit-mobile a {
  margin-right: 6px;
}

.audit-desktop {
  display: none;
}

.badges {
  font-size: 1rem;
  border: 1px solid #FFF;
  border-radius: 6px;
  padding: 4px 8px;
  color: #FFF;
  text-decoration: none;
}

.low-risk, .kyc {
  color: #AAA;
}

.paladin {
  position: relative;
}

footer {
  margin: 1rem 0 1rem;
  padding: 0.25rem;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

footer a {
  color: #FFF;
  text-decoration: none;
  margin-right: 0.75rem;
}

footer a svg {
  fill: #FFF;
}

.fa-beat {
  animation:fa-beat 5s ease infinite;
  color: red;
}

.info {
  background-color: rgba(0, 0, 0, .2);
  border-radius: 6px;
  padding: 0.5rem;
  color: #FFF;
  text-shadow: rgba(0, 0, 0, .2);
}

@keyframes fa-beat {
  0% {
    transform:scale(1);
  }
  10% {
    transform:scale(1.125);
  }
  20% {
    transform:scale(1);
  }
  30% {
    transform:scale(1.125);
  }
  40% {
    transform:scale(1);
  }
  50% {
    transform:scale(1.125);
  }
  60% {
    transform:scale(1);
  }
  70% {
    transform:scale(1.125);
  }
  80% {
    transform:scale(1);
  }
  90% {
    transform:scale(1.125);
  }
}

.muted {
  color: #AAA;
}

@media (min-width: 576px) {
  .wrapper {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .wrapper {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .wrapper {
    max-width: 960px;
    align-items: flex-start;
  }

  main, footer {
    width: 26rem;
  }

  h1 {
    margin: 2rem 0 0;
  }

  .specs {
    background: transparent;
  }

  .actions {
    align-items: center;
    flex-grow: 1;
  }

  footer {
    background: none;
  }

  .is-desktop {
    display: inline;
  }

  .audit-mobile {
    display: none;
  }

  .audit-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;

    bottom: 2.5rem;
    right: 2rem;
    width: 300px;
    font-size: 1.5rem;
  }

  .audit-desktop a {
    position: relative;
  }

  .audit-desktop a span {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid #AAA;
    padding: 4px;
    border-radius: 6px;
    font-size: 12px;
    background-color: rgba(0, 0, 0, .5);
    color: #AAA;
  }

  .bsc-news span {
    top: -12px !important;
    right: -12px !important;
    /* width: 80px; */
  }

  .btn {
    font-size: 2.25rem;
  }
}

@media (min-width: 1200px) {
  .wrapper {
    max-width: 1140px;
  }
}

@media (min-width: 1536px) {
  /* body {
    background-size: 33% auto, auto 100%, cover;
  } */

  .wrapper {
    max-width: 1140px;
  }

  main, footer {
    width: 26rem;
  }
}
